.container {
  display: grid;
  column-gap: 2rem;
  position: relative;
  top: 8rem;
  padding: 0 1.6rem;

  @media screen and (min-width: 768px) {
    top: 9rem;
    grid-template-columns: 2fr 1fr;
  }
}

.header, .liner {
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03);

  p {
    visibility: hidden;
  }
}

.header {
  padding: 8rem 0;
}


@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.liner {
  padding: 4rem 0;
  margin-top: 3rem;
}

.stack {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 1.6rem;
  margin-top: 3rem;

  & > div {
    padding: 15rem 0;
  }

  & > div:last-child {
    grid-column: span 2;
  }


  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;

    & > div:last-child {
      grid-column: span 1;
    }
  }
}