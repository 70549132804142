.container {
    height: 18px;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 70px;
    justify-content: center;
    margin: 0 auto;
}

.container--comment {
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.container--chat {
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.dots-flow:before {
    -webkit-animation: dots-flow 0.85s infinite ease;
    animation: dots-flow 0.85s infinite ease;
    border-radius: 100%;
    content: '';
    height: 5px;
    position: absolute;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-40%, -40px);
    transform: translate(-40%, -40px);
    width: 5px;
}

@-webkit-keyframes dots-flow {
    0%,
    100% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    35% {
        -webkit-box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    50% {
        -webkit-box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
    }
    65% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
    }
}

@keyframes dots-flow {
    0%,
    100% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    35% {
        -webkit-box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 4px white, 0px 32px 0 0 white, 26px 32px 0 0 white;
    }
    50% {
        -webkit-box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
        box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px white, 26px 32px 0 0 white;
    }
    65% {
        -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
        box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px white;
    }
}

@-webkit-keyframes whirl {
    0% {
        -webkit-transform: scale(0) rotate(-45deg) translateY(0);
        transform: scale(0) rotate(-45deg) translateY(0);
    }
    50% {
        -webkit-transform: scale(1.25) rotate(960deg) translateY(100%);
        transform: scale(1.25) rotate(960deg) translateY(100%);
    }
    100% {
        -webkit-transform: scale(0) rotate(2000deg) translateY(0);
        transform: scale(0) rotate(2000deg) translateY(0);
    }
}

@keyframes whirl {
    0% {
        -webkit-transform: scale(0) rotate(-45deg) translateY(0);
        transform: scale(0) rotate(-45deg) translateY(0);
    }
    50% {
        -webkit-transform: scale(1.25) rotate(960deg) translateY(100%);
        transform: scale(1.25) rotate(960deg) translateY(100%);
    }
    100% {
        -webkit-transform: scale(0) rotate(2000deg) translateY(0);
        transform: scale(0) rotate(2000deg) translateY(0);
    }
}

.whirl_container {
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 175px;
    overflow: hidden;
    width: 70%;
    display: flex;
    margin: 5rem auto;
}

.whirl::before {
    -webkit-animation: whirl 2s infinite linear;
    animation: whirl 2s infinite linear;
    background-color: var(--color-primary);
    border-radius: 100%;
    content: '';
    height: 30px;
    width: 30px;
}

.rings_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 175px;
}

@-webkit-keyframes rings {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50%,
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rings {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50%,
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rings:after,
.rings:before {
    -webkit-animation: rings 1s infinite linear;
    animation: rings 1s infinite linear;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 6px solid rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    content: '';
    height: 30px;
    left: 50%;
    margin-top: -15px;
    position: absolute;
    top: 50%;
    width: 30px;
}

.rings:after {
    border-left-color: #fff;
    margin-left: -2px;
}

.rings:before {
    animation-direction: reverse;
    border-right-color: #fff;
    margin-left: -28px;
}

/**
  * Meetup
  *
  * @author jh3y - jheytompkins.com
*/

.container-meetup {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 175px;
    position: relative;
}

@-webkit-keyframes meetup {
    0%, 100% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    }
    50% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
    }
}

@keyframes meetup {
    0%, 100% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    }
    50% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
    }
}

.meetup {
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    height: 10px;
    width: 10px;
}

.meetup div {
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-animation: meetup 1.25s infinite ease;
    animation: meetup 1.25s infinite ease;
    background: white;
    border-radius: 100%;
}

.meetup div:nth-child(1) {
    --rotation: 90;
}

.meetup div:nth-child(2) {
    --rotation: 180;
}

.meetup div:nth-child(3) {
    --rotation: 270;
}

.meetup div:nth-child(4) {
    --rotation: 360;
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/**
  * Bloom
  *
  * @author jh3y - jheytompkins.com
*/
.bloom {
    height: 15px;
    position: relative;
    width: 15px; }
.bloom:before, .bloom:after {
    border-radius: 100%;
    content: '';
    display: block;
    height: 15px;
    width: 15px; }
.bloom:before {
    background-color: var(--color-accent);
    -webkit-animation: scale-up 1s infinite ease;
    animation: scale-up 1s infinite ease; }
.bloom:after {
    -webkit-animation: scale 1s infinite;
    animation: scale 1s infinite;
    border: 2px solid var(--color-accent);
    left: 0;
    position: absolute;
    top: 0; }

@-webkit-keyframes scale-up {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1); } }

@keyframes scale-up {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1); } }

@-webkit-keyframes scale {
    0%, 50% {
        opacity: 0; }
    55% {
        opacity: 1; }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2); } }

@keyframes scale {
    0%, 50% {
        opacity: 0; }
    55% {
        opacity: 1; }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2); } }