.topbar {
  position: fixed;
  height: 7rem;
  padding: 1.6rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 25;
  box-shadow: var(--shadow-light);
  width: 100%;

  .preview {
    padding: 1rem;
    background-color: var(--color-primary-light-1);
    width: 95%;
    margin: 0 auto;
    border-radius: 0.5rem;
    font-size: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    left: 0;
  }

  &__nav {
    display: flex;
    align-items: center;
    position: relative;

    .toggle {
      width: 4rem;
      height: 4rem;
      object-fit: contain;
      background-color: var(--color-primary);
      fill: white;
      border-radius: 50%;
      padding: 8px;
      cursor: pointer;
    }

    @media screen and (min-width: 768px) {
      .toggle {
        display: none;
      }

      .funding_header {
        margin-left: 19.5rem !important;
      }
    }

    .funding_header {
      font-weight: 500;
      font-size: 1.9rem;
      margin-left: 0.8rem;
    }
  }

  &__search {
    background-color: #f7f7f7;
    backdrop-filter: blur(40px);
    border: 1px solid #707070;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 1rem 1.6rem;

    input {
      outline: none;
      border: none;
      flex-basis: 67%;
      margin-right: 0.8rem;
      background-color: inherit;
      font-size: 1.28rem;
      font-weight: 500;

      &::-webkit-input-placeholder {
        font-weight: 500;
        color: #000;
      }
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    padding-right: 0.4rem;
    position: relative;

    .profile {
      display: flex;
      align-items: center;
      column-gap: 1.2rem;
      padding-right: 0.4rem;
      position: relative;
      cursor: pointer;
    }

    &__photo {
      height: 5rem;
      width: 5rem;
      object-fit: cover;
      object-position: center top;
      border-radius: 50%;
      margin-left: 1rem;
      cursor: pointer;
    }

    .notification {
      position: relative;

      svg {
        height: 2.25rem;
        width: 2.25rem;
        cursor: pointer;
      }

      span {
        font-size: 1.1rem;
        height: 1.7rem;
        width: 1.7rem;
        border-radius: 50%;
        background-color: var(--color-primary);
        color: #ffffff;
        position: absolute;
        bottom: 1.6rem;
        left: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__profile {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
        position: relative;
        top: 0.5rem;
      }

      p {
        font-size: 1.6rem;
        margin: 0;
        cursor: pointer;

        &:last-child {
          color: var(--color-grey-dark-2);
          font-size: 1.4rem;
          position: relative;
          bottom: 0.3rem;
        }

        span {
          position: relative;
          top: 0.3rem;
          width: 1.5rem;
          height: 1.5rem;

          svg {
            transition: all 0.3s ease-in-out;
            width: 1.5rem;
            height: 1.5rem;
          }

          .flip {
            transform: scaleY(-1);
          }
        }
      }
    }
  }
}

.nav_menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 20rem;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .africa_image {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  &_active {
    left: 0;
  }

  @media screen and (min-width: 768px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 20rem;
    height: 100vh;
    align-self: start;
    justify-self: start;
  }

  ul {
    list-style: none;
    margin: 0 !important;
    overflow-y: auto;
    height: 90vh;
    position: fixed;
    background-color: #fff;
    padding-right: 2rem !important;

    &::-webkit-scrollbar {
      width: 3px;
      height: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-primary-light-1);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-accent);
      border-radius: 10px;
    }
  }
}

.nav_item {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1rem 1rem 1rem 1.5rem;
  text-decoration: none;
  color: black;
  transition: ease-in-out all 0.3s;
  border-bottom-right-radius: 3rem;
  border-top-right-radius: 3rem;
  position: relative;

  p {
    font-size: 1.5rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    background-color: var(--color-grey-light-4);
  }

  &__active {
    background-color: var(--color-primary);
    border-bottom-right-radius: 3rem;
    border-top-right-radius: 3rem;
    position: relative;
    font-size: 1.7rem !important;
    font-weight: 500;
    color: white;

    svg {
      fill: white !important;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 3px;
      height: 60%;
      margin: 3px 0;
      background-color: white;
    }

    &:hover {
      background-color: var(--color-primary);
    }
  }
}

.nav_icon {
  width: 2.2rem;
  height: 2.2rem;
  align-self: baseline !important;
}

.logo {
  width: 100%;
  margin-bottom: 3rem;
  padding: 1.2rem;
  position: relative;
  top: 1rem;
  cursor: pointer;
  transform: scale(1.1);

}

.skills_tooltip {
  max-width: 300px !important;
  //background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  //color: black !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}

.head {
  font-size: 1.44rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
  padding: 0 0.4rem 0 1.2rem;
  color: #707070;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 2.8rem;
    width: 90%;
    left: 5%;
    border-top: 1px solid rgba(112, 112, 112, 0.7);
  }
}

.noImage {
}

.tool_tip {
  width: 47rem;
  background-color: #fff;
  padding: 1.6rem 1.28rem;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;

    h2 {
      font-weight: 500;
      font-size: 1.92rem;
    }

    p {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      svg {
        fill: var(--color-primary);
        width: 1.92rem;
        height: 1.92rem;
      }

      span {
        display: inline-block;
        color: var(--color-primary);
        font-size: 1.28rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #707070;
  }
}

.funding_drop {
  svg {
    width: 2rem;
    height: 2rem;
    fill: black;
    position: absolute;
    right: 1rem;
    top: 1rem;
    bottom: 0;
  }

  .links {
    display: none;
    font-size: 1.6rem;
    text-align: right;
    cursor: pointer;
    margin-right: 1.6rem;

    a {
      display: block;
      text-decoration: none;
      color: #000;

      &.active_link {
        color: var(--color-accent);
      }
    }
  }

  .show_links {
    display: block;
  }
}
