@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');

:root {
  --color-primary: #018158;
  --color-primary-dark: #014e35;
  --color-accent: #ffab00;
  --color-accent-dark: #f4ad3d;
  --color-accent-light: rgba(255, 170, 0, 0.24);
  --color-primary-light-1: #d7f8ee;
  --color-primary-light-2: #ffc41b;
  --color-grey-light-1: #f7f7f7;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-blue: #2445d4;
  --color-blue-1: #768ce8;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --color-red-1: #fdf2f2;
  --color-red-2: #e83b3b;

  --color-green-1: #e8fff8;
  --color-green: #018158;

  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
}

$bp-largest: 81.5em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; //900px
$bp-small: 43.75em; // 700px
$bp-smaller: 31.25em; // 500px

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 52.5%;
  scroll-behavior: smooth;

  @media only screen and (max-width: $bp-largest) {
    font-size: 47%;
  }
}

body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: var(--color-grey-light-1);
  font-weight: 400;

  &::-webkit-scrollbar {
    width: 10px;
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-primary-light-1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }
}

.recharts-pie-labels > .recharts-layer {
  background-color: var(--color-grey-light-4) !important;
}

@media screen and (min-width: 768px) {
  .App {
    position: relative;
  }

  .main-content {
    position: relative;
    padding-left: 21.2rem;
    max-width: 1920px;
    margin: 0 auto;
  }
}

.carousel-custom-root {
  min-width: 0 !important;
}

.carousel-container {
  width: 80%;
  margin: 0 auto;
  /* add other styles to see if they affect the carousel */
}

.pie_legend {
  display: grid;
  align-items: center;
  grid-template-columns: 1.2rem 1fr 1fr;
  grid-gap: 0.5rem;
  font-size: 1.28rem !important;

  div {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: inline-block;
  }

  span:last-child {
    font-weight: 500;
  }
}

.ent_per_chart {
  margin-left: -4rem !important;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.tabs {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 2rem 0 1rem 0;
  overflow: hidden;
}

.tabs [class^='tab'] label,
.tabs [class*=' tab'] label {
  color: #707070;
  letter-spacing: 0.33px;
  cursor: pointer;
  display: block;
  font-size: 1.44rem;
  padding: 2rem 0;
  text-align: center;
}

.tabs [class^='tab'] [type='radio'],
.tabs [class*=' tab'] [type='radio'] {
  border-bottom: 1px solid #707070;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-bottom: 2px;
}

.tabs [class^='tab'] [type='radio']:hover,
.tabs [class^='tab'] [type='radio']:focus,
.tabs [class*=' tab'] [type='radio']:hover,
.tabs [class*=' tab'] [type='radio']:focus {
  border-bottom: 1px solid #ffab00;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs [class^='tab'] [type='radio']:checked,
.tabs [class*=' tab'] [type='radio']:checked {
  border-bottom: 3px solid #ffab00;
  border-radius: 15px;
  color: #ffab00;
  padding-bottom: 0;
}

.tabs [class^='tab'] [type='radio']:checked + div,
.tabs [class*=' tab'] [type='radio']:checked + div {
  opacity: 1;
}

.tabs [class^='tab'] [type='radio'] + div,
.tabs [class*=' tab'] [type='radio'] + div {
  display: block;
  opacity: 0;
  padding: 2rem 0;
  width: 90%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs .tab-2 {
  flex: 1 1 0px;
}

.tabs .tab-2 [type='radio'] + div {
  width: 200%;
  margin-left: 200%;
}

.tabs .tab-2 [type='radio']:checked + div {
  margin-left: 0;
}

.tabs .tab-2:last-child [type='radio'] + div {
  margin-left: 100%;
}

.tabs .tab-2:last-child [type='radio']:checked + div {
  margin-left: -100%;
}

.label__active {
  color: var(--color-primary) !important;
  font-weight: 500;
}

abbr {
  text-decoration: none;
}

.ui-datepicker {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 -2px 10px 3px #dddddd;
  padding: 1rem;

  &-header {
    display: flex;
    column-gap: 1.5rem;
    font-size: 1.44rem;
    align-items: center;

    & > * {
      cursor: pointer;
    }
  }

  &-next {
    order: 2;
    font-size: 1.2rem;
  }

  &-prev {
    font-size: 1.2rem;
  }

  &-title {
    font-weight: 500;
  }

  &-calender {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.Toastify__toast--error {
  font-size: 1.5rem;
}

.Toastify__toast--success {
  font-size: 1.5rem;
}

.react-datepicker {
  font-size: 1.2rem !important;
  width: max-content !important;

  &__day {
    width: 2rem !important;
  }

  &__navigation--next {
    display: none !important;
    width: 3.2rem !important;
    height: 3.2rem !important;
  }

  &__navigation--previous {
    display: none !important;
    width: 3.2rem !important;
    height: 3.2rem !important;
  }

  &__header {
    font-size: 1.2rem !important;
  }
}

.react-datepicker__input-container {
  width: 100%;
  border-color: #707070 !important;
  border: none;

  input {
    padding: 1.5rem;
    border: 1px solid #707070;
    border-radius: 5px;
    outline: none;
    width: 100%;
  }
}

.noClick {
  cursor: not-allowed !important;
}

.custom-crop .ReactCrop__crop-selection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
}

.reactour__helper--is-open {
  font-size: 1.6rem;
}

.circular-progress {
  height: 15rem;
}

.pagination-container {
  margin: 2rem auto;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 1.6rem;
  border-radius: 5px;
  column-gap: 1.6rem;

  li {
    display: inline-block;
    cursor: pointer;

    &.selected a {
      background-color: var(--color-accent);
      color: black;
    }


    a {
      color: #707070;
      padding: 4px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      text-decoration: none;
      display: grid;
      place-items: center;
      height: 4rem;

      svg {
        width: 2rem;
        height: auto;
      }

      &:hover {
        background-color: var(--color-accent-light);
        color: black;
      }
    }

  }

}

.slick-next {
  //right: -25px !important;
  &:before {
    color: var(--color-accent) !important;
  }
}

.slick-prev {
  left: 3px !important;

  &:before {
    color: var(--color-accent) !important;
  }
}

.slick-arrow {
  //display: none !important;
  z-index: 30 !important;
}

.slick-track {
  display: flex !important;
  align-items: stretch !important;
  column-gap: 2rem !important;
  justify-content: flex-start !important;

}

.slick-slide {
}

.carousel-item {
  margin: 0 1.6rem;
  cursor: pointer;
}

.checkbox {
  $block: &;

  cursor: pointer;
  display: flex;
  align-items: center;

  &__input {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    opacity: 0;
    cursor: pointer;

    &:checked + #{$block}__icon .tick {
      stroke-dashoffset: 0;
    }
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    overflow: visible;

    .tick {
      stroke-dasharray: 20px;
      stroke-dashoffset: 20px;
      transition: stroke-dashoffset .2s ease-out;
    }
  }

  &__label {
    margin-left: 0.5em;
  }
}

.clip {
  background-color: #ffffff;
  opacity: 0.23;
  position: absolute;
  z-index: 21;
}

.big-circle {
  height: 13rem;
  clip-path: circle(50% at 50% 50%);
  top: 0.5rem;
  right: 5rem;
  width: 13rem;
}

.small-circle {
  height: 5rem;
  clip-path: circle(50% at 50% 50%);
  top: 2rem;
  right: 19rem;
  width: 5rem;
}

.corner {
  height: 12rem;
  width: 12rem;
  clip-path: circle(50% at 99% 1%);
  top: 0;
  right: 0;
}

.text-yellow-500 {
  color: #ffab00 !important;
}

.text-green-500 {
  color: #018158 !important;
}

.text-red-500 {
  color: #e83b3b !important;
}

.text-blue-500 {
  color: #2445d4 !important;
}

.text-gray-500 {
  color: #707070 !important;
}

.capitalize {
  text-transform: capitalize;
}

.rdt_TableHeader, .rdt_TableHeadRow {
  background-color: var(--color-primary-light-1) !important;
  font-size: 1.6rem !important;
  font-weight: bold;
}

.preview {
  padding: 1rem;
  background-color: var(--color-primary-light-1);
  width: 95%;
  margin: 0 auto;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.slick-dots{
  bottom: 1.5rem !important;

}

/*
input {
  transform: scale(0.875);
  transform-origin: left center;
  margin-right: -14.28%;
}*/


.survey-container {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
}

/* Make sure survey fields are responsive */
.survey-container .sv_main {
  display: flex;
  flex-direction: column;
}

.survey-container .sv_qstn {
  margin-bottom: 1rem;
}

/* Adjust field widths */
.survey-container .sv_qstn .sv_qstn_head {
  width: 100%;
}

.survey-container .sv_qstn .sv_qstn_body {
  width: 100%;
}

@media (max-width: 768px) {
  .survey-container {
    padding: 1rem;
  }
}